import React from "react";
import Reveal from "../Animation/Reveal";

const Hero = () => {
  return (
    <Reveal
      className="relative flex flex-col w-full h-screen md:h-[calc(100vh+300px)] items-center overflow-hidden"
      style={{
        backgroundImage: "url('/images/star-field.png')",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img
        src="/images/ellipse.png"
        alt="moon"
        className="absolute bottom-0 xl:bottom-[-550px] w-full"
      />

      <img
        src="/images/strips-overlay.svg"
        alt="strips"
        className="absolute h-screen object-cover"
      />
      <img
        src="/images/perspective-grid.svg"
        alt="grid"
        className="absolute bottom-0 w-full bg-black"
      />
      <div className="absolute top-[144px] flex flex-col md:w-[700px] items-center justify-center text-center">
        <h1
          className="text-[76px] text-[#4284FF] drop-shadow-[0_0_24px_rgba(66,132,255,1)]"
          style={{ fontFamily: "Array-Regular" }}
        >
          DePINs
        </h1>
        <p className="text-lg">
          Where Technolovy Weaves the Web of Tomorrow, Illuminating the Path
          with Innovation and Connectivity
        </p>
      </div>
    </Reveal>
  );
};

export default Hero;
