import { motion as m, useAnimation, useInView } from "framer-motion";
import React, { useEffect, useRef } from "react";

const Reveal = ({ children, className, style, delay, duration }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  return (
    <m.div
      ref={ref}
      className={className}
      style={style}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: duration, delay: delay }}>
      {children}
    </m.div>
  );
};

Reveal.defaultProps = {
  children: null,
  delay: 0.5,
  duration: 0.5,
  inline: false,
  className: "",
  style: "",
};

export default Reveal;
