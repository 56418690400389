import { useState, useEffect } from "react";

const CountdownTimer = ({ date, setToolsEnabled, completedText }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateCountdown = () => {
      // const target = new Date(date).getTime() - 8 * 60 * 60 * 1000;
      const target = new Date(date).getTime();
      const now = new Date().getTime();
      const difference = target - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        setTimeRemaining({
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        });
      } else {
        setTimeRemaining();
        if (setToolsEnabled) {
          setToolsEnabled(false); // Notify parent that the countdown has ended
        }
      }
    };

    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer);
  }, [date, setToolsEnabled]);

  return (
    <div className="flex gap-[8px] md:gap-[32px]">
      {timeRemaining ? (
        <h1>
          {timeRemaining.days}d {timeRemaining.hours}h {timeRemaining.minutes}m{" "}
          {timeRemaining.seconds}s
        </h1>
      ) : (
        <h1>{completedText}</h1>
      )}
    </div>
  );
};

export default CountdownTimer;
