import { createConfig, http } from "wagmi";
import { iotex, iotexTestnet } from "wagmi/chains";
import { walletConnect } from "wagmi/connectors";

export const wagmiConfig = createConfig({
  chains: [iotex, iotexTestnet],

  transports: {
    [iotex.id]: http(),
    [iotexTestnet.id]: http(),
  },
  connectors: [
    walletConnect({
      projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
    }),
  ],
});
