import React from "react";

const Button = ({
  variant,
  colour,
  className,
  style,
  onClick,
  children,
  disabled,
}) => {
  const colours = {
    blue: "bg-[#4284FF] hover:bg-[#356BD1] active:bg-[#2853A3]",
    black: "bg-black hover:bg-[#333333] active:bg-[#222222]",
  };

  const variants = {
    primary: `${colours[colour]} text-white disabled:bg-[#E0E2E5] disabled:text-[#A1A7B2] py-[8px] px-[22px]`,
    secondary:
      "bg-tranparent text-white border-[#F7F8F9] hover:border-[#EFF0F2] active:border-[#E0E2E5] disabled:border-[#D0D3D8] disabled:text-[#A1A7B2] py-[8px] px-[22px]",
    tertiary:
      "bg-tranparent text-white hover:text-[#4284FF] active:text-[#356BD1] disabled:text-[#A1A7B2] py-[8px] px-[22px]",
  };

  return (
    <button
      className={`${variants[variant]} ${className}`}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
