import React, { useState, useEffect } from "react";

const TypeWriting = ({ textArray, delay, inline = false }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [completedTexts, setCompletedTexts] = useState([""]);

  useEffect(() => {
    if (currentStringIndex < textArray.length) {
      if (currentIndex < textArray[currentStringIndex].length) {
        const timeout = setTimeout(() => {
          setCurrentText(
            (prevText) =>
              prevText + textArray[currentStringIndex][currentIndex],
          );
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, delay);

        return () => clearTimeout(timeout);
      } else if (currentStringIndex < textArray.length - 1) {
        const timeout = setTimeout(() => {
          setCompletedTexts((prevTexts) => [...prevTexts, currentText]);
          setCurrentText("");
          setCurrentIndex(0);
          setCurrentStringIndex((prevIndex) => prevIndex + 1);
        }, delay);

        return () => clearTimeout(timeout);
      } else if (
        currentStringIndex === textArray.length - 1 &&
        currentText !== ""
      ) {
        setCompletedTexts((prevTexts) => [...prevTexts, currentText]);
        setCurrentText("");
      }
    }
  }, [currentIndex, currentStringIndex, delay, textArray, currentText, inline]);

  return (
    <div className={inline ? "inline" : ""}>
      {completedTexts.map((text, index) => (
        <div key={index} className={inline ? "inline" : ""}>
          {text}
        </div>
      ))}
      <div className={inline ? "inline" : ""}>{currentText}</div>
    </div>
  );
};

export default TypeWriting;
