import React, { memo, useMemo } from "react";
import TypeWriting from "../Component/Animation/TypeWriting";

const TerminalItem = memo(({ id, index, message, sender, isLatest }) => {
  const content = useMemo(() => {
    return (
      <div key={id}>
        <br />
        {sender === "user" ? (
          <span className="text-orange-400">
            <span className="text-white">>_ </span> You:{" "}
          </span>
        ) : (
          <span className="text-[#4284FF]">
            <span className="text-white">>_ </span>DePINs AI:{" "}
          </span>
        )}

        <span>
          {sender === "user" ? (
            <span>{message}</span>
          ) : isLatest ? (
            <TypeWriting textArray={[message]} delay={10} inline />
          ) : (
            <span>{message}</span>
          )}
        </span>
      </div>
    );
  }, []);

  return content;
});

export default TerminalItem;
