import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import {
  HOME,
  CLAIM,
  COMING_SOON,
  LIVE,
  RESULT,
  DEXX_VICTIM,
  STAKING,
  ASK_AI
} from "./path";
import Home from "../Page/Home";
import ClaimPage from "../Page/ClaimPage";
import ComingSoon from "../Page/ComingSoon";
import Live from "../Page/Live";
import ResultPage from "../Page/ResultPage";
import DexxVictim from "../Page/DexxVictim";
import Staking from "../Page/Staking";
import AskAi from "../Page/AskAi";

export default function Router() {
  const checkAddressExist = () => !!sessionStorage.getItem("wallet_address");
  const routes = [
    {
      path: HOME,
      element: <Home />,
    },
    // {
    //   path: CLAIM,
    //   element: checkAddressExist() ? <ClaimPage /> : <Navigate to={HOME} />,
    // },
    // {
    //   path: RESULT,
    //   element: checkAddressExist() ? <ResultPage /> : <Navigate to={HOME} />,
    // },
    {
      path: LIVE,
      element: <Live />,
    },
    {
      path: COMING_SOON,
      element: <ComingSoon />,
    },
    {
      path: DEXX_VICTIM,
      element: <DexxVictim />,
    },
    {
      path: STAKING,
      element: <Staking />,
      // element: checkAddressExist() ? <Staking /> : <Navigate to={HOME} />,
    },
    {
      path: ASK_AI,
      element: <AskAi />,
    },
    {
      path: "*",
      element: <Navigate to={HOME} />,
    },
  ];

  return useRoutes(routes);
}
