import React, { useEffect, useState, useRef } from "react";
import MainComponent from "../Component/MainComponent";
import Reveal from "../Component/Animation/Reveal";
import TypeWriting from "../Component/Animation/TypeWriting";
import TerminalItem from "../Component/TerminalItem";
import { Typewriter } from "react-simple-typewriter";
import axios from "axios";

const AskAi = () => {
  const [disableInput, setDisableInput] = useState(true);
  const [renderNameForAnimation1, setRenderNameForAnimation1] = useState(false);
  const [renderAnimation1, setRenderAnimation1] = useState(false);
  const [renderAnimation2, setRenderAnimation2] = useState(false);
  const [renderInput, setRenderInput] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [renderReponseAnimation, setRenderReponseAnimation] = useState(false);
  const startTimeRef = useRef(Date.now());
  const messageEl = useRef(null);
  const anchor = useRef(null);
  const intervalRef = useRef(null);
  const lastActiveTimeRef = useRef(Date.now());

  // This function is for detecting weather the tab is inactive or not.
  // This is necessary because typewriter animation stops when tab is inactive and messes with the order of renderings.
  // If tab is inactive, it pauses the time for animations to be rendered and avoids mixed timelines for animations.

  const handleVisibilityChange = () => {
    if (document.hidden) {
      lastActiveTimeRef.current = Date.now();
      // @ts-ignore
      clearInterval(intervalRef.current);
    } else {
      const timeSpentInactive = Date.now() - lastActiveTimeRef.current;
      startTimeRef.current += timeSpentInactive;

      startCountdown();
    }
  };

  // This starts the countdown for animations to rendered.
  const startCountdown = () => {
    // @ts-ignore
    intervalRef.current = setInterval(() => {
      const elapsed = Date.now() - startTimeRef.current;
      if (elapsed >= 3650 && !renderNameForAnimation1) {
        setRenderNameForAnimation1(true);
      }
      if (elapsed >= 3650 + 200 && !renderAnimation1) {
        setRenderAnimation1(true);
      }
      if (elapsed >= 3650 + 200 + 2550 && !renderAnimation2) {
        setRenderAnimation2(true);
      }
      if (elapsed >= 3950 + 2550 + 2150 && !renderInput) {
        setRenderInput(true);
        setDisableInput(false);
      }
    }, 100);
  };

  // This triggers the handleVisibilityChange function.
  useEffect(() => {
    startCountdown();

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // @ts-ignore
      clearInterval(intervalRef.current);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // This scrolls down when user sent his input or response comes from the AI agent and the content is overflowed in the container.
  useEffect(() => {
    if (messageEl.current) {
      const observer = new MutationObserver(() => {
        // @ts-ignore
        messageEl.current.scroll({
          // @ts-ignore
          top: messageEl.current.scrollHeight,
          behavior: "smooth",
        });
      });

      observer.observe(messageEl.current, {
        childList: true,
        subtree: true,
        characterData: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  // This displays blinking cursor animation on the screen while user waits response from the AI agent.
  // This is necessary for good UX. If this is not shown, user might think there will not be an answer if response took some time.
  useEffect(() => {
    if (
      messages.length > 0 &&
      // @ts-ignore
      messages[messages.length - 1].sender === "user"
    ) {
      setTimeout(() => {
        setRenderReponseAnimation(true);
      }, 750);
    } else {
      setRenderReponseAnimation(false);
    }
  }, [messages]);

  // This makes request to AI, and pushes the response to "messages" array to rendered on the screen.
  // Until a response comes from the AI, input is disabled to avoid any spam. When request is resolved and response is arrived, input is enabled.
  function makeRequestToAi(input) {
    axios
      .post(
        // @ts-ignore
        process.env.REACT_APP_LANGBASE_WORKER_URL,
        {
          userInput: input,
        },
        {
          headers: {
            "Content-Type": "text/plain",
          },
        },
      )
      .then((response) => {
        setMessage(response.data, "ai");
      })
      .finally(() => {
        setDisableInput(false);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  function setMessage(message, sender) {
    // @ts-ignore
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: Date.now(),
        sender: sender,
        message: message,
      },
    ]);
  }

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  return (
    <MainComponent>
      <Reveal
        className="flex w-full h-full items-center justify-start px-[16px] flex-col mt-[110px]"
        style={{
          backgroundImage: "url('/images/victim-bg-blue.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}>
        <div className="flex flex-col text-center max-w-[70%]">
          <h1
            className="text-[76px] text-[#4284FF] leading-none mt-2.5 mb-3.5 md:mt-0 md:mb-0 md:leading-normal drop-shadow-[0_0_24px_rgba(66,132,255,1)]"
            style={{ fontFamily: "Array-Regular" }}>
            DePINs AI Agent
          </h1>
          <p>
            DePINs AI is a helpful assistant designed to provide information and
            answer your questions about everything you want to know about
            DePINs.
          </p>
        </div>
        <div className="border-2 border-[#4284FF] mx-4 md:w-[704px] p-[32px] bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)] mt-14  flex flex-col justify-between space-y-2 h-[540px] min-h-[540px] max-h-[540px]">
          <div
            id="scroller"
            ref={messageEl}
            className="overflow-y-auto pb-4 h-full">
            <TypeWriting
              textArray={[
                ">_ Establishing connection to DePINs network...",
                ">_ Synchronizing with decentralized nodes... [███---] 42%",
                ">_ Synchronizing with decentralized nodes... [███████] 100%",
                ">_ Verifying Smart Contracts... [Success]\n",
              ]}
              delay={10}
            />
            <br></br>

            {renderNameForAnimation1 ? (
              <Reveal delay={0} duration={0.15}>
                {[
                  ">_ ",
                  <span className="text-[#4284FF]">DePINs AI: </span>,
                ].map((part, index) => (
                  <React.Fragment key={index}>{part}</React.Fragment>
                ))}

                <span>
                  {renderAnimation1 ? (
                    <TypeWriting
                      textArray={[
                        "Hello. I am DePINs AI. I am a chatbot designed to assist you about DePIN related questions. Here are some example questions you can ask me:",
                      ]}
                      delay={10}
                      inline
                    />
                  ) : null}
                  <br />
                  <br />
                </span>
              </Reveal>
            ) : null}

            {renderAnimation2 ? (
              <TypeWriting
                textArray={[
                  <br />,
                  "   /.. “What is DePINs?”",
                  "   /.. “What do you think of $IOTX?”",
                  "   /.. “Give me a technical analysis for this coin: xxxxxx...”",
                ]}
                delay={10}
              />
            ) : null}

            {messages.map((m, i) => (
              <TerminalItem
                // @ts-ignore
                key={m.id}
                // @ts-ignore
                id={m.id}
                // @ts-ignore
                message={m.message}
                index={i}
                // @ts-ignore
                sender={m.sender}
                isLatest={i === messages.length - 1}
              />
            ))}

            {renderReponseAnimation ? (
              <Reveal delay={0.55} duration={0.1}>
                <br />
                {[
                  ">_ ",
                  <span className="text-[#4284FF]">DePINs AI: </span>,
                ].map((part, index) => (
                  <React.Fragment key={index}>{part}</React.Fragment>
                ))}
                <Typewriter words={[""]} cursor loop cursorStyle={"_"} />
              </Reveal>
            ) : null}

            <div id="anchor" ref={anchor}></div>
          </div>

          {renderInput ? (
            <Reveal delay={0.1} duration={0.5}>
              <div className="flex w-full border border-white p-[12px]">
                <textarea
                  disabled={disableInput}
                  placeholder=">_ Ask anything..."
                  name="question"
                  id="question"
                  value={userInput}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();

                      if (userInput) {
                        setMessage(userInput, "user");
                        let nimput = userInput;
                        setUserInput("");
                        setDisableInput(true);
                        makeRequestToAi(nimput);
                      }
                    }
                  }}
                  className="w-full text-white bg-black outline-none placeholder:opacity-20 resize-none"
                  rows={2}></textarea>
                <button
                  // @ts-ignore
                  onClick={(e) => {
                    if (userInput) {
                      setMessage(userInput, "user");
                      let nimput = userInput;
                      setUserInput("");
                      setDisableInput(true);
                      makeRequestToAi(nimput);
                    }
                  }}
                  className="text-lg w-11 h-11 hover:bg-white/20">
                  ↵
                </button>
              </div>
            </Reveal>
          ) : null}
        </div>
      </Reveal>
    </MainComponent>
  );
};

export default AskAi;
