import { useCallback } from "react";

const useShorten = () => {
  const shorten = useCallback((address, startLength = 6, endLength = 4) => {
    if (!address) return "";
    return `${address.substring(0, startLength)}...${address.substring(
      address.length - endLength
    )}`;
  }, []);

  return shorten;
};

export default useShorten;
