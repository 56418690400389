import React from "react";
import Marquee from "react-fast-marquee";
import Reveal from "../Animation/Reveal";

const RollingAds = () => {
  return (
    <Reveal>
      <Marquee
        className="flex w-full p-[1px] bg-gradient-to-r from-[#4384FF0A] to-[#4384FF0A] via-[#226CF966]"
        autoFill
      >
        <div className="bg-black py-[32px]">
          <div className="flex justify-center ml-[32px] gap-[32px] items-center text-lg">
            <img src="/images/depins-icon.svg" alt="depins-icon" />
            {/* <Icon icon="prime:twitter" /> */}
            <p>DEPINS</p>
          </div>
        </div>
      </Marquee>
    </Reveal>
  );
};

export default RollingAds;
