import React from "react";
import MainComponent from "../Component/MainComponent";
import Reveal from "../Component/Animation/Reveal";

const ComingSoon = () => {
  const text = [
    '>_ Initializing "Claim Page" sequence...\n',
    ">_ ",
    <span className="text-[#4284FF]">STATUS:</span>,
    " Feature not yet active.\n",
    ">_ ",
    <span className="text-[#4284FF]">SYSTEM MESSAGE:</span>,
    ' "Claim page is currently undergoing maintenance and development."\n',
    ">_ ",
    <span className="text-[#4284FF]">In-progress</span>,
    "... [██-----]",
    <span className="text-[#FF3030]">31%</span>,
    "\n>_ ",
    <span className="text-[#FF3030]">
      ERROR CODE: 503 - Service Temporarily Unavailable.
    </span>,
    "\n\n>_ Please remain patient as deployment protocols are executed.\n",
    ">_ Estimated time to completion: UNKNOWN. Calculating...\n",
    ">_ Stay connected for real-time updates:\n",
    "    /.. Twitter: ",
    <a
      href="https://x.com/depinsapp"
      className="text-[#4284FF] hover:underline"
    >
      https://x.com/depinsapp
    </a>,
    "\n    /.. Telegram: ",
    <a href="http://t.me/DePINsCTO" className="text-[#4284FF] hover:underline">
      http://t.me/DePINsCTO
    </a>,
  ];

  return (
    <MainComponent>
      <div className="flex relative flex-col w-full h-screen items-center justify-center p-[16px]">
        <video
          muted
          autoPlay
          loop
          className="absolute w-full h-full object-cover top-0"
        >
          <source src="/animations/depins_grid_3.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute w-full h-screen bg-black bg-opacity-80 backdrop-blur-sm" />
        <Reveal className="flex w-full h-screen items-center justify-center pt-24">
          <div className="border-2 border-[#4284FF] w-[704px] p-[32px]  bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)]">
            <p className="whitespace-pre-wrap">
              {text.map((part, index) => (
                <React.Fragment key={index}>{part}</React.Fragment>
              ))}
            </p>
          </div>
        </Reveal>
      </div>
    </MainComponent>
  );
};

export default ComingSoon;
