import React, { useEffect, useState } from "react";
import Reveal from "../Animation/Reveal";
import { db } from "../../firebase-config";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useAccount } from "wagmi";
import useShorten from "../../hooks/shorten";
import { Icon } from "@iconify/react/dist/iconify.js";
import ReactPaginate from "react-paginate";
import { LoadingOverlay } from "../LoadingOverlay";

const Earnings = () => {
  const [headerData, setHeaderData] = useState([
    {
      id: "currentDePINsStaked",
      title: "Current DePINs Staked",
      value: 0,
    },
    {
      id: "totalDePINsStaked",
      title: "Total DePINs Staked",
      value: 0,
    },
    {
      id: "totalDePINsEarned",
      title: "Total DePINs Earned",
      value: 0,
    },
    {
      id: "totalBinobitEarned",
      title: "Total Binobit Earned",
      value: "-",
    },
  ]);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cursors, setCursors] = useState([]);

  const { address } = useAccount();
  const shorten = useShorten();

  const dayDifference = (dateInSeconds) => {
    const stakeDate = new Date(dateInSeconds * 1000);
    const currentDate = new Date();

    const differenceInTime = currentDate.getTime() - stakeDate.getTime();

    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  };

  const resetData = () => {
    setHeaderData([
      {
        id: "currentDePINsStaked",
        title: "Current DePINs Staked",
        value: 0,
      },
      {
        id: "totalDePINsStaked",
        title: "Total DePINs Staked",
        value: 0,
      },
      {
        id: "totalDePINsEarned",
        title: "Total DePINs Earned",
        value: 0,
      },
      {
        id: "totalBinobitEarned",
        title: "Total Binobit Earned",
        value: "-",
      },
    ]);
  };

  const fetchHeaderData = async () => {
    resetData();
    try {
      const q = query(
        collection(db, process.env.REACT_APP_DB_COLLECTION),
        where("walletAddress", "in", [
          address,
          address.toLowerCase(),
          address.toUpperCase(),
        ])
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (
          Number(docData.stakeAmount) > 0 &&
          !isNaN(Number(docData.stakeAmount))
        ) {
          setHeaderData((prev) => [
            {
              id: "currentDePINsStaked",
              title: "Current DePINs Staked",
              value:
                (docData.stakeType === 0 && docData.claimAmount === null) ||
                (docData.stakeType === 1 && docData.unstakeHash === null)
                  ? prev[0].value + Number(docData.stakeAmount)
                  : prev[0].value + 0,
            },
            {
              id: "totalDePINsStaked",
              title: "Total DePINs Staked",
              value:
                Number(docData.stakeAmount) +
                (prev[1].value ? prev[1].value : 0),
            },
            {
              id: "totalDePINsEarned",
              title: "Total DePINs Earned",
              value:
                docData.stakeType === 0
                  ? ((Number(docData.stakeAmount) * 0.5) / 365) * 61 +
                    prev[2].value
                  : ((Number(docData.stakeAmount) * 0.15) / 365) *
                      (Number(docData.daysStaked) ??
                        dayDifference(docData.stakeDate.seconds)) +
                    prev[2].value,
            },
            {
              id: "totalBinobitEarned",
              title: "Total Binobit Earned",
              value: "-",
            },
          ]);
        }
      });
    } catch (e) {
      console.error(e);
      console.log("No data found");
    }
  };

  const fetchTableData = async (page = 0) => {
    try {
      setLoading(true);
      let q;

      if (page > 0 && cursors[page - 1]) {
        q = query(
          collection(db, process.env.REACT_APP_DB_COLLECTION),
          where("walletAddress", "in", [
            address,
            address.toLowerCase(),
            address.toUpperCase(),
          ]),
          orderBy("stakeDate", "desc"),
          startAfter(cursors[page - 1]),
          limit(10)
        );
      } else {
        q = query(
          collection(db, process.env.REACT_APP_DB_COLLECTION),
          where("walletAddress", "in", [
            address,
            address.toLowerCase(),
            address.toUpperCase(),
          ]),
          orderBy("stakeDate", "desc"),
          limit(10)
        );
      }

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTableData(items);

        if (cursors.length <= page) {
          setCursors((prev) => [
            ...prev,
            querySnapshot.docs[querySnapshot.docs.length - 1],
          ]);
        }

        if (page === 0) {
          const totalItemsQuery = query(
            collection(db, process.env.REACT_APP_DB_COLLECTION),
            where("walletAddress", "in", [
              address,
              address.toLowerCase(),
              address.toUpperCase(),
            ])
          );

          const totalItemsSnapshot = await getDocs(totalItemsQuery);

          setPageCount(Math.ceil(totalItemsSnapshot.size / 10));
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      console.log("No data found");
    }
  };

  const handlePageClick = (event) => {
    fetchTableData(event.selected);
  };

  useEffect(() => {
    fetchHeaderData();
    fetchTableData();
  }, []);

  return (
    <Reveal className="flex flex-col w-full gap-[48px] lg:max-w-[80%]">
      {loading && <LoadingOverlay />}
      <div className={`grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 w-full`}>
        {headerData.map((data) => (
          <div
            className="flex flex-col items-center justify-center text-center"
            key={data.id}
          >
            <p>{data.title}</p>
            <h1
              className="text-[#4284FF] text-[31px]"
              style={{ fontFamily: "Array-Regular" }}
            >
              {data.value.toLocaleString()}
            </h1>
          </div>
        ))}
      </div>
      <div className="flex flex-col w-full gap-[24px] items-center justify-center">
        <div className="border-2 border-[#4284FF] flex flex-col w-full bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)] overflow-x-auto text-nowrap">
          <table>
            <thead>
              <tr className="border-b border-[#FFFFFF33] text-[#FFFFFFCC]">
                <th className="text-start font-normal py-[12px] px-[16px]">
                  #
                </th>
                <th className="text-start font-normal py-[12px] px-[16px]">
                  Staking
                </th>
                <th className="text-start font-normal py-[12px] px-[16px]">
                  APY
                </th>
                <th className="text-start font-normal py-[12px] px-[16px]">
                  Staked Amount
                </th>
                <th className="text-start font-normal py-[12px] px-[16px]">
                  DePINs Earned
                </th>
                {/* <th className="text-start font-normal py-[12px] px-[16px]">
                Binobit Earned
              </th> */}
                <th className="text-start font-normal py-[12px] px-[16px]">
                  Stake
                </th>
                <th className="text-start font-normal py-[12px] px-[16px]">
                  Unstake
                </th>
                <th className="text-start font-normal py-[12px] px-[16px]">
                  Withdrawal
                </th>
              </tr>
            </thead>
            {tableData.length <= 0 ? (
              <tbody>
                <tr>
                  <td className="py-[12px] px-[16px] text-center" colSpan="8">
                    No data found
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {tableData.map((data, index) => (
                  <tr key={data.stakeHash}>
                    <td className="py-[12px] px-[16px]" width="50px">
                      {index + 1}
                    </td>
                    <td className="py-[12px] px-[16px]" width="152px">
                      {data.stakeType === 0 ? "Locked" : "Flexible" ?? "-"}
                    </td>
                    <td className="py-[12px] px-[16px]" width="150px">
                      {data.stakeType === 0 ? "50%" : "15%" ?? "-"}
                    </td>
                    <td className="py-[12px] px-[16px]" width="200px">
                      {Number(data.stakeAmount) ?? "-"}
                    </td>
                    <td className="py-[12px] px-[16px]" width="">
                      {data.stakeType === 0
                        ? ((Number(data.stakeAmount) * 0.5) / 365) * 61
                        : ((Number(data.stakeAmount) * 0.15) / 365) *
                          (data.daysStaked ??
                            dayDifference(data.stakeDate.seconds))}
                    </td>
                    {/* <td className="py-[12px] px-[16px]" width="">
                    {data.stakeType === 0
                      ? Number(ethers.formatEther(data.stakeAmount)) * 61 * 0.05
                      : Number(ethers.formatEther(data.stakeAmount)) *
                        (data.daysStaked ??
                          dayDifference(data.stakeDate.seconds)) *
                        0.025}
                  </td> */}
                    <td className="py-[12px] px-[16px]" width="162px">
                      {data.stakeDate && data.stakeHash ? (
                        <div className="flex flex-col">
                          <p>
                            {new Date(
                              data.stakeDate.seconds * 1000
                            ).toLocaleDateString()}
                          </p>
                          <a
                            // href={`https://testnet.iotexscan.io/tx/${data.stakeHash}`}
                            href={`https://iotexscan.io/tx/${data.stakeHash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:underline text-[#4284FF]"
                          >
                            {shorten(data.stakeHash)}
                          </a>
                        </div>
                      ) : (
                        <div>
                          <p>-</p>
                        </div>
                      )}
                    </td>
                    <td className="py-[12px] px-[16px]" width="162px">
                      {data.unstakedDate && data.unstakeHash ? (
                        <div className="flex flex-col">
                          <p>
                            {new Date(
                              data.unstakedDate.seconds * 1000
                            ).toLocaleDateString()}
                          </p>
                          <a
                            // href={`https://testnet.iotexscan.io/tx/${data.unstakeHash}`}
                            href={`https://iotexscan.io/tx/${data.unstakeHash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:underline text-[#4284FF]"
                          >
                            {shorten(data.unstakeHash)}
                          </a>
                        </div>
                      ) : (
                        <div>
                          <p>-</p>
                        </div>
                      )}
                    </td>
                    <td className="py-[12px] px-[16px]" width="162px">
                      {data.claimDate && data.claimHash ? (
                        <div className="flex flex-col">
                          <p>
                            {new Date(
                              data.claimDate.seconds * 1000
                            ).toLocaleDateString()}
                          </p>
                          <a
                            // href={`https://testnet.iotexscan.io/tx/${data.claimHash}`}
                            href={`https://iotexscan.io/tx/${data.claimHash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:underline text-[#4284FF]"
                          >
                            {shorten(data.claimHash)}
                          </a>
                        </div>
                      ) : (
                        <div>
                          <p>-</p>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <div className="flex items-center justify-center w-full p-4">
          {pageCount > 0 && (
            <ReactPaginate
              onPageChange={(e) => handlePageClick(e)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              breakLabel="..."
              previousLabel={<Icon icon="ri:arrow-left-line" />}
              nextLabel={<Icon icon="ri:arrow-right-line" />}
              nextClassName="paginator-page-btn right-4 border border-[#FFFFFF33]"
              previousClassName="paginator-page-btn right-[58px] border border-[#FFFFFF33]"
              containerClassName="flex rounded-xl font-medium gap-2"
              activeClassName="paginator-page-btn bg-[#4284FF]"
              pageClassName="paginator-page-btn border border-[#FFFFFF33]"
              breakClassName="paginator-page-btn"
            />
          )}
        </div>
      </div>
    </Reveal>
  );
};

export default Earnings;
