import React, { useEffect, useState } from "react";
import Button from "./Button";
import { Icon } from "@iconify/react";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { CLAIM, DEXX_VICTIM, HOME, LIVE, STAKING, ASK_AI } from "../Router/path";
import WalletModal from "./WalletModal";
import { useAccount, useDisconnect, useReadContracts } from "wagmi";
import useShorten from "../hooks/shorten";
import iotex_token_abi from "../hooks/abi/iotex_token_abi";
import { ethers } from "ethers";

const Header = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showMenuDropDown, setShowMenuDropDown] = useState(false);

  const { address } = useAccount();
  const { data: accountBalance, isLoading: accountBalanceLoading } =
    useReadContracts({
      contracts: [
        {
          abi: iotex_token_abi,
          functionName: "balanceOf",
          address: process.env.REACT_APP_IOTEX_TOKEN_CONTRACT_ADDRESS,
          args: [address],
        },
      ],
    });
  const { disconnect, disconnectSuccess } = useDisconnect();
  const shorten = useShorten();
  const navigate = useNavigate();

  const toggleMenuModal = () => {
    setShowMenuModal(!showMenuModal);
  };

  const toggleWalletModal = () => {
    setShowWalletModal(!showWalletModal);
  };

  const toggleDropDown = () => {
    setShowMenuDropDown(!showMenuDropDown);
  };

  return (
    <div className="fixed top-0 left-0 z-30 flex w-full py-[24px] px-[32px] justify-between items-center bg-transparent">
      {/* Wallet Modal */}
      {showWalletModal && <WalletModal toggleModal={toggleWalletModal} />}

      {/* Menu Modal */}
      {showMenuModal && (
        <Modal toggleModal={toggleMenuModal}>
          <div className="flex flex-col w-[264px]">
            <div>
              <img
                src="/images/header-logo.png"
                className="h-[24px] cursor-pointer"
                alt="header-logo"
                onClick={() => navigate(HOME)}
              />
            </div>
            <div className="flex flex-col gap-[24px] py-[48px]">
              {/* <p
                className="cursor-pointer"
                onClick={() => {
                  if (address) {
                    navigate(CLAIM);
                  } else {
                    navigate(LIVE);
                  }
                }}
              >
                Check & Claim
              </p> */}
              <p
                className="cursor-pointer"
                onClick={() => {
                  navigate(DEXX_VICTIM);
                }}
              >
                Dexx Victim
              </p>
              <p
                className="cursor-pointer"
                onClick={() => {
                  navigate(STAKING);
                }}
              >
                {">_ Staking"}
              </p>
              <a
                href="https://v21.mimo.exchange/swap"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-[8px]"
              >
                Buy
                <Icon icon="quill:link-out" />
              </a>
              <a
                href="https://bridge.iotex.io/bridge"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-[8px]"
              >
                Bridge
                <Icon icon="quill:link-out" />
              </a>
              <p
                className="cursor-pointer hover:underline"
                onClick={() => {
                  navigate(ASK_AI);
                }}
              >
                {">_ Ask AI"}
              </p>
            </div>
            <div className="flex items-center gap-[24px] text-[24px]">
              <a
                href="/Lightpaper.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="heroicons:document-solid" />
              </a>
              <a
                href="http://t.me/DePINsCTO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="mage:telegram" />
              </a>
              <a
                href="https://x.com/depinsapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="prime:twitter" />
              </a>
            </div>
          </div>
        </Modal>
      )}

      {/* Left part */}
      <div className="flex items-center gap-[24px]">
        <Icon
          icon="heroicons-outline:menu-alt-2"
          className="text-[24px] lg:hidden"
          onClick={toggleMenuModal}
        />
        <div className="gap-[24px] items-center hidden lg:flex">
          <img
            src="/images/header-logo.png"
            className="h-[32px] cursor-pointer"
            alt="header-logo"
            onClick={() => navigate(HOME)}
          />
          {/* <p
            className="cursor-pointer hover:underline"
            onClick={() => {
              if (address) {
                navigate(CLAIM);
              } else {
                navigate(LIVE);
              }
            }}
          >
            Check & Claim
          </p> */}
          <p
            className="cursor-pointer hover:underline"
            onClick={() => {
              navigate(DEXX_VICTIM);
            }}
          >
            Dexx Victim
          </p>
          <p
            className="cursor-pointer hover:underline"
            onClick={() => {
              navigate(STAKING);
            }}
          >
            {">_ Staking"}
          </p>
          <a
            href="https://v21.mimo.exchange/swap"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-[8px] hover:underline"
          >
            Buy
            <Icon icon="quill:link-out" />
          </a>
          <a
            href="https://bridge.iotex.io/bridge"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-[8px] hover:underline"
          >
            Bridge
            <Icon icon="quill:link-out" />
          </a>
          <p
            className="cursor-pointer hover:underline"
            onClick={() => {
              navigate(ASK_AI);
            }}
          >
            {">_ Ask AI"}
          </p>
        </div>
      </div>

      {/* Right part */}
      <div className="flex gap-[32px] items-center">
        <div className="lg:flex items-center gap-[24px] hidden text-[24px]">
          <a
            href="/Lightpaper.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="heroicons:document-solid" />
          </a>
          <a
            href="http://t.me/DePINsCTO"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="mage:telegram" />
          </a>
          <a
            href="https://x.com/depinsapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="prime:twitter" />
          </a>
        </div>
        <div className="relative flex items-center">
          {address && (
            <div className="border-y border-l border-[#4284FF] bg-black p-[8px]">
              <img
                src="/images/iotex-icon.png"
                className="w-[24px] h-[24px]"
                alt="iotex-icon"
              />
            </div>
          )}
          <Button
            variant="tertiary"
            className="border border-[#4284FF] bg-black"
            onClick={address ? toggleDropDown : toggleWalletModal}
          >
            {isLoading && (
              <span className="i-line-md:loading-twotone-loop inline-flex mr-1 w-4 h-4"></span>
            )}{" "}
            <p>{address ? shorten(address) : "Connect Wallet"}</p>
          </Button>
          {showMenuDropDown && (
            <div className="absolute top-[calc(100%+8px)] right-0 min-w-[200px] bg-black text-sm">
              <div className="flex gap-[8px] items-center justify-between border border-[#4284FF] p-[16px]">
                <div className="flex gap-[8px] items-center">
                  <img src="/images/depins-round-icon.svg" alt="depins-icon" />
                  {accountBalance && (
                    <p>
                      {accountBalance[0].result
                        ? Number(
                          ethers.formatEther(accountBalance[0].result)
                        ).toLocaleString()
                        : 0}
                    </p>
                  )}
                </div>
                <p>$DePINs</p>
              </div>
              <Button
                className="flex w-full h-full justify-start hover:text-[#4284FF] border-x border-b border-[#4284FF] p-[16px]"
                onClick={() => {
                  disconnect();
                  sessionStorage.removeItem("wallet_address");
                  sessionStorage.removeItem("chain_id");
                  setShowMenuDropDown(false);
                  navigate(HOME);
                }}
              >
                Disconnect Wallet
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
