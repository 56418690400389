import React, { useState, useEffect } from "react";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import Modal from "./Modal";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { CLAIM, COMING_SOON } from "../Router/path";

const WalletModal = ({ toggleModal }) => {
  const { address, isConnecting } = useAccount();
  const { connectAsync, connectors, isPending, connectSuccess } = useConnect();
  const navigate = useNavigate();

  const [pendingConnectorId, setPendingConnectorId] = useState("");

  const walletConnect = (connector) => {
    return new Promise(async (resolve, reject) => {
      try {
        setPendingConnectorId(connector.id);
        const connectSuccess = await connectAsync({ connector });
        toggleModal();
        resolve(connectSuccess);
        window.location.reload();
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <Modal toggleModal={toggleModal}>
      <div className="flex flex-col items-center justify-center gap-[32px]">
        {/* Modal Header */}
        <h2 className="text-lg">Connect a Wallet</h2>

        {/* List of wallets */}
        <div className="grid lg:grid-cols-4 grid-cols-2 w-full gap-x-[8px] gap-y-[32px] overflow-y-scroll max-h-[230px] no-scrollbar">
          {connectors.map((connector) => (
            <div className="w-fit h-fit" key={connector.id}>
              <Button
                className="flex flex-col w-full items-center justify-center gap-[8px] text-md"
                onClick={() => {
                  walletConnect(connector)
                    .then((val) => {
                      if (val) {
                        sessionStorage.setItem(
                          "wallet_address",
                          val.accounts[0]
                        );
                        sessionStorage.setItem("chain_id", val.chainId);
                        toggleModal();
                        // navigate(CLAIM);
                        // navigate(COMING_SOON);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <img
                  src={
                    connector.name !== "WalletConnect"
                      ? connector.icon
                      : "/images/walletconnect.png"
                  }
                  alt={connector.name}
                  className="bg-white rounded-lg p-[6px] w-[48px] h-[48px]"
                />
                <p>{connector.name}</p>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default WalletModal;
